export const EDIT_LISTING_MAPPING = {
  digimon: ['game', 'format', 'tournamentdetails'],
  'flesh-and-blood': ['game', 'format', 'tournamentdetails'],
  lorcana: ['game', 'format', 'tournamentdetails'],
  'magic-the-gathering': [
    'game',
    'format',
    'rules',
    'twoHeadedGiantMagicTheGathering',
    'tournamentdetails',
  ],
  'one-piece': ['game', 'format', 'buddyBattleOnePiece', 'tournamentdetails'],
  pokemon: ['game', 'format', 'tournamentdetails'],
  'yu-gi-oh': ['game', 'format', 'tierLevelYugioh', 'tournamentdetails'],
  dbscg: ['game', 'format', 'tierLevelYugioh', 'tournamentdetails'],
  'star-wars': ['game', 'format', 'tournamentdetails'],
  altered: ['game', 'format','mulitplayer', 'tournamentdetails'],
  'fftcg': ['game', 'format', 'tournamentdetails'],
  schwarz: ['game', 'format', 'tournamentdetails'],
  'cardfight': ['game', 'format', 'tournamentdetails'],
  fow: ['game', 'format', 'tournamentdetails'],
  'warcraft': ['game', 'format', 'tournamentdetails'],
  keyForge: ['game', 'format', 'tournamentdetails'],
  'gundam': ['game', 'format','teamkampf', 'tournamentdetails'],
  shadowverse: ['game', 'format', 'tournamentdetails'],
  'grand': ['game', 'format', 'tournamentdetails'],
};
