import React from 'react';

import css from './Sections.module.css';
import classNames from 'classnames';
import { NamedLink, PrimaryButton } from '../../../components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const steps = [
  {
    img:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/00/b4edaa13e3b6c2f2ce7e4c3a17f9679a22b163?auto=format&fit=clip&h=400&w=400&s=b0a960e13ae8343274d61801ab67b5c8',
    title: '1. Liste dein Turnier',
    description:
      'Leg einen Preis und die Anzahl möglicher Teilnehmer fest, terminiere das Turnier, und füge ein paar Fotos von deinen Räumlichkeiten hinzu.',
  },
  {
    img:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/0a/95989272e681027595144664bd8c326224ce29?auto=format&fit=clip&h=400&w=400&s=35b0f579c816ef6b600cb6f01de8714c',
    title: '2. Erhalte Registrierungen',
    description:
      'Erhalte Registrierungen. Bei voll ausgebuchten Turnieren, kommt die Warteliste ins Spiel.',
  },
  {
    img:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/f4/31431061c7e94d54c807a4030dda139a1bbd66?auto=format&fit=clip&h=400&w=400&s=3317c7bd589cf41d225eaa8f7bd518cf',
    title: '3. Verwalte Registrierungen',
    description:
      'In deinem Backend hast du eine Übersicht der Registrierungen je Turnier. Hier siehst du auch, wer schon bezahlt hat, oder seine Deckliste einregereicht hat.',
  },
];

function Step({ step }) {
  return (
    <div className={css.tournamentContent}>
      <div className={css.tournamentImg}>
        <img src={step.img} alt="img" />
      </div>
      <div className={css.tournamentInfo}>
        {step.title && <h4>{step.title}</h4>}
        {step.description && <p>{step.description}</p>}
      </div>
    </div>
  );
}

function SectionOrganizeTournament(props) {
  const { intl, viewport } = props;
  const title = 'Du organisierst Turniere?';
  const description = 'Über Tournazone kannst du noch mehr Teilnehmer finden.';
  const history = useHistory();

  return (
    <div className={classNames(css.tournamentRegisterSec, css.sectionPadding)}>
      <div className={css.contentWidth}>
        <div className={css.sectionHead}>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        <div className={css.sectionContent}>
          {steps.map((step, index) => (
            <Step key={index} step={step} />
          ))}{' '}
        </div>
        <div className={css.freeRegistrationSec}>
          <PrimaryButton
            className={css.createAccountBtn}
            type="button"
            onClick={() => history.push('/l/new')}
          >
            Liste dein erstes Turnier
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

export default SectionOrganizeTournament;
