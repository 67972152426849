import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../../../util/reactIntl';
import {
  Button,
  FieldCheckbox,
  FieldLocationAutocompleteInput,
  FieldSelect,
  FieldTextInput,
  Form,
} from '../../../../components';
import DateRangePickerComponent from '../DateRangePicker/DateRangerPicker';
import { isArrayLength, safeFind } from '../../../../util/genericHelpers';
import {
  CASUAL,
  DECKLIST,
  FORMAT,
  GAME,
  MAGIC_THE_GATHERING,
  RULES,
  TOURNAMENT_DETAILS,
} from '../../../../util/enums';

import css from './LandingSearchForm.module.css';
import IconSearchDesktop from '../../../NotFoundPage/SearchForm/IconSearchDesktop';
import { EDIT_LISTING_MAPPING } from '../../../../config/configExtra';

const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;

const LandingSearchFormComponent = props => {
  const [dateRange, setDateRange] = useState(null);
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          values,
          intl,
          form,
          isSearchPageFilter,
          handleSubmit,
          isHeroSearch,
          config,
        } = formRenderProps;

        let listingFields = config.listing.listingFields;
        const selectedGame = values?.game;

        listingFields = selectedGame
          ? listingFields
              .filter(field => EDIT_LISTING_MAPPING[selectedGame].includes(field.key))
              .map(field => {
                if (field.key === FORMAT) {
                  return {
                    ...field,
                    enumOptions: field.enumOptions.filter(
                      option =>
                        option.option?.startsWith(`${selectedGame}-`) ||
                        option.option?.includes(selectedGame)
                    ),
                  };
                }
                if (field.key === RULES && selectedGame === MAGIC_THE_GATHERING) {
                  return {
                    ...field,
                    enumOptions: field.enumOptions.filter(option => option.option !== CASUAL),
                  };
                }
                return field;
              })
          : listingFields.filter(field => field.key === GAME);

        // Extracting games options
        const gamesOptions = isArrayLength(listingFields)
          ? safeFind(listingFields, f => f?.key === GAME)?.enumOptions
          : null;

        const formatOptions = safeFind(listingFields, f => f?.key.includes(FORMAT))?.enumOptions;
        // const rulesOptions = safeFind(listingFields, f => f?.key.toLowerCase().includes(RULE))
        //   ?.enumOptions;

        // Tournament details extraction
        const tournamentDetails = safeFind(listingFields, f => f?.key === TOURNAMENT_DETAILS);
        const tournamentDetailsOptions = tournamentDetails?.enumOptions
          ? tournamentDetails?.enumOptions?.filter(o => o?.option !== DECKLIST)
          : null;

        const classes = classNames(rootClassName || css.root, className, {
          [css.searchPageFilter]: isSearchPageFilter,
          [css.heroSearch]: isHeroSearch,
        });

        const isCurrentLocationSelected = values?.location?.search === '';
        const headings = [
          {
            label: intl.formatMessage({ id: 'LandingSearchForm.beliebteLabel' }),
            option: intl.formatMessage({ id: 'LandingSearchForm.beliebteKey' }),
            disable: true,
          },
          {
            label: intl.formatMessage({ id: 'LandingSearchForm.alleLabel' }),
            option: intl.formatMessage({ id: 'LandingSearchForm.alleKey' }),
            disable: true,
          },
        ];
        const formattedOptions = [
          headings[0], // "Beliebte TCGs"
          ...gamesOptions.slice(0, 6), // First 6 popular games
          headings[1], // "Alle TCGs"
          ...gamesOptions.slice(6) // Remaining games
        ];
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FieldSelect
              className={css.heroSearchFld}
              id="game"
              name="game"
              label={intl.formatMessage({ id: 'LandingSearchForm.tradingCardGame' })}
            >
              <option disabled value="">
                {intl.formatMessage({ id: 'LandingSearchForm.tradingCardGameOption' })}
              </option>
              {isArrayLength(gamesOptions) ? (
                formattedOptions.map((o, i) => (
                  <option className={css.optionDisabled} key={i} value={o?.option} disabled={o?.disable}>
                    {o?.label}
                  </option>
                ))
              ) : (
                <></>
              )}
            </FieldSelect>
            <FieldSelect
              disabled={!values?.game}
              className={css.heroSearchFld}
              id="format"
              name="format"
              label={intl.formatMessage({ id: 'LandingSearchForm.format' })}
            >
              <option disabled value="">
                {intl.formatMessage({ id: 'LandingSearchForm.chooseAnOption' })}
              </option>
              {isArrayLength(formatOptions) ? (
                formatOptions.map((o, i) => (
                  <option key={i} value={o?.option}>
                    {o?.label}
                  </option>
                ))
              ) : (
                <></>
              )}
            </FieldSelect>
            <DateRangePickerComponent
              rootClassName={css.heroSearchFld}
              dateLabel={intl.formatMessage({ id: 'LandingSearchForm.dateLabel' })}
              isLandingDateRange={true}
              dateRange={dateRange}
              setDateRange={setDateRange}
              isSearchPageFilter={isSearchPageFilter}
              onDateRangeChange={dates => form.change('dates', dates)}
              orientation="vertical"
            />{' '}
            <FieldLocationAutocompleteInput
              rootClassName={css.heroSearchFld}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              name="location"
              label={intl.formatMessage({ id: 'LandingSearchForm.address' })}
              placeholder={intl.formatMessage({
                id: isCurrentLocationSelected
                  ? 'EditListingLocationForm.currentLocationPlaceholder'
                  : 'EditListingLocationForm.addressPlaceholder',
              })}
              useDefaultPredictions={true}
              format={v => v}
              valueFromForm={values?.location}
              isHeroSearch={isHeroSearch}
              isLandingDateRange={true}
            />
            {/* <FieldSelect
              className={css.heroSearchFld}
              id="distance"
              name="distance"
              disabled={!values?.location}
              label={intl.formatMessage({ id: 'LandingSearchForm.distance' })}
            >
              <option value="" disabled>
                {' '}
                {intl.formatMessage({ id: 'LandingSearchForm.distanceOption' })}
              </option>
              <option value="10"> {intl.formatMessage({ id: 'LandingSearchForm.10' })}</option>
              <option value="20"> {intl.formatMessage({ id: 'LandingSearchForm.20' })}</option>
              <option value="50"> {intl.formatMessage({ id: 'LandingSearchForm.50' })}</option>
              <option value="100"> {intl.formatMessage({ id: 'LandingSearchForm.100' })}</option>
              <option value="200"> {intl.formatMessage({ id: 'LandingSearchForm.200' })}</option>
              <option value="500"> {intl.formatMessage({ id: 'LandingSearchForm.500' })}</option>
            </FieldSelect> */}
            {!isMobile && (
              <FieldTextInput
                className={css.heroSearchFld}
                type="text"
                id="keywords"
                name="keywords"
                label={intl.formatMessage({ id: 'LandingSearchForm.keywords' })}
              />
            )}
            <div className={classNames(css.searchButtonWrapper, css.heroSearchFld)}>
              <Button
                className={classNames(css.heroSearchBtn, {
                  [css.searchIcon]: isSearchPageFilter,
                  [css.searchBtn]: !isSearchPageFilter,
                })}
                onClick={() => props.onSubmit(values)}
                type="submit"
              >
                <IconSearchDesktop /> {intl.formatMessage({ id: 'LandingSearchForm.search' })}
              </Button>
              <div
                className={css.clearButton}
                role="button"
                onClick={() => {
                  form.reset();
                  setDateRange(null);
                }}
              >
                {intl.formatMessage({ id: 'LandingSearchForm.clear' })}
              </div>
            </div>
            <div className={css.tournamentDetails}>
              {/* <div className={css.checkboxLabel}>
                {intl.formatMessage({ id: 'LandingSearchForm.tournamentdetails' })}
              </div> */}
              <div className={css.tournamentDetailList}>
                {isArrayLength(tournamentDetailsOptions)
                  ? tournamentDetailsOptions.map(o => (
                      <FieldCheckbox
                        key={o?.option}
                        id={o?.option}
                        name="tournamentdetails"
                        label={o?.label}
                        value={o?.option}
                        rootClassName={css.heroSearchFld}
                      />
                    ))
                  : null}
              </div>
            </div>
            {/* {isArrayLength(rulesOptions) ? (
              <div className={css.tournamentDetails}>
                <div className={css.tournamentDetailList}>
                  {rulesOptions.map(o => (
                    <FieldCheckbox
                      key={o?.option}
                      id={o?.option}
                      name={rulesSubcategory?.key}
                      label={o?.label}
                      value={o?.option}
                      rootClassName={css.heroSearchFld}
                    />
                  ))}
                </div>
              </div>
            ) : null} */}
          </Form>
        );
      }}
    />
  );
};

LandingSearchFormComponent.defaultProps = { rootClassName: null, className: null };

LandingSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const LandingSearchForm = injectIntl(LandingSearchFormComponent);

export default LandingSearchForm;
